import RecurringExpensesForm from '../components/RecurringEXpensesForm';
import ReExList from '../components/RecurringExpenses';
import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
interface REFormProps {}

const REForm: React.FunctionComponent<REFormProps> = () => {
    return (
        <>
            <Box sx={{ m: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <RecurringExpensesForm></RecurringExpensesForm>
                    </Grid>
                    <Grid xs={6}>
                        <ReExList month={0} update={0}></ReExList>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default REForm;
