import * as React from 'react';
import { useEffect, useState, useReducer } from 'react';
import { NewRecurringExpesnsesInterface } from '../api/Movement';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { TextField, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { config } from '../api/config';
import API from '../api/api_client';
import getSessionStorageOrDefault from '../shared/session';

interface RecurringExpensesFormProp {}

//const validationSchema: Yup.SchemaOf<NewRecurringExpesnsesInterface> = Yup.object().shape({
//    re_description: Yup.string().required(),
//    re_cash: Yup.number().required(),
//    re_day_of_month: Yup.number().required(),
//    re_month_of_year: Yup.number().required()
//});
//const uri: string = `${config.baseUrl}/addre`;

const RecurringExpensesForm: React.FunctionComponent<RecurringExpensesFormProp> = () => {
    const [open, setOpen] = useState(false);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

    const formik = useFormik({
        initialValues: {
            re_description: '',
            re_cash: '',
            re_day_of_month: '',
            re_month_of_year: ''
        },
        //      validationSchema: validationSchema,
        onSubmit: (values) => {
            //           console.log(values);
            const token = getSessionStorageOrDefault('id_token', false);

            const customConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : ''
                }
            };
            API.post(`addre`, values, customConfig).finally(() => forceUpdate());
        }
    });

    return (
        <>
            <Box sx={{ '& .MuiTextField-root': { m: 1, width: '47ch' }, boxShadow: 0 }}>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <Paper elevation={3}>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack spacing={4}>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            inputProps={{}}
                                            fullWidth={true}
                                            variant="standard"
                                            size="small"
                                            id="re_description"
                                            name="re_description"
                                            label="Beschreibung"
                                            value={formik.values.re_description}
                                            onChange={formik.handleChange}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack spacing={4}>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            variant="standard"
                                            size="small"
                                            id="re_day_of_month"
                                            name="re_day_of_month"
                                            label="Tag"
                                            type={'number'}
                                            value={formik.values.re_day_of_month}
                                            onChange={formik.handleChange}
                                            helperText="Bitte den Tag des Monats eingeben wenn die Zahlung fällig ist"
                                        />
                                        <TextField
                                            variant="standard"
                                            size="small"
                                            id="re_month_of_year"
                                            name="re_month_of_year"
                                            type={'number'}
                                            label="Monat"
                                            value={formik.values.re_month_of_year}
                                            onChange={formik.handleChange}
                                            helperText="Bitte den Monat eingeben wenn die Zahlung fällig ist: 0 = jeden Monat"
                                        />
                                    </Stack>
                                </Stack>
                                <Stack spacing={4}>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                                            variant="standard"
                                            fullWidth={true}
                                            size="small"
                                            id="re_cash"
                                            name="re_cash"
                                            label="Kosten"
                                            type={'number'}
                                            value={formik.values.re_cash}
                                            onChange={formik.handleChange}
                                        />
                                    </Stack>
                                </Stack>
                                <div>
                                    <Button color="primary" variant="contained" type="submit">
                                        Speichern
                                    </Button>
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default RecurringExpensesForm;
