import { useEffect, useState, useReducer } from 'react';
import API from '../api/api_client';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Input } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import MovementsList from '../components/Movemenst';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ReExList from '../components/RecurringExpenses';
import { date } from 'yup/lib/locale';
import getSessionStorageOrDefault from '../shared/session';
import Balance from '../components/Balance';
interface MvFormProps {}

interface NewMovement {
    revenue_description: String;
    revenue_category: String;
    revenue_date: Date;
    revenue: String;
    cash: number;
}

interface CategoryList {
    id: number;
    re_cat_description: string;
}

//const validationSchema: Yup.SchemaOf<NewMovement> = Yup.object().shape({
//    email: Yup.string().email().required(),
/*    password: Yup.string()
        .required()
        .min(8)
        .matches(/^[a-z0-9]+$/i, 'Password should contain alphabets and numbers only'),
 */
//  revenue_description: Yup.string().required(),
//  revenue_category: Yup.string().required()
//});

const MvForm: React.FunctionComponent<MvFormProps> = () => {
    const now = new Date();
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [year, setYear] = useState(now.getFullYear());
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date().toLocaleDateString('de-DE').split(',')[0]);
    const [category_list, setCategoryList] = useState<CategoryList[]>([]);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

    useEffect(() => {
        const token = getSessionStorageOrDefault('id_token', false);
        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${token}` : ''
            }
        };

        API.get(`recat`, customConfig)
            .then((response) => setCategoryList(response.data))
            .catch((error) => setError(error.message))
            .finally(() => setLoaded(true));
    }, [reducerValue]);
    const formik = useFormik({
        initialValues: {
            revenue: 'OUT',
            revenue_description: '',
            revenue_category: '',
            revenue_date: '',
            cash: ''
        },
        //validationSchema: validationSchema,
        onSubmit: (values) => {
            const token = getSessionStorageOrDefault('id_token', false);

            const customConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : ''
                }
            };
            API.post(`addmv`, values, customConfig).finally(() => forceUpdate());
        }
    });

    return (
        <>
            <Box sx={{ m: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div>
                                <Paper elevation={3}>
                                    <Box p={2}>
                                        <form onSubmit={formik.handleSubmit}>
                                            <TextField
                                                type="date"
                                                variant="standard"
                                                placeholder=" "
                                                size="small"
                                                fullWidth
                                                id="revenue_date"
                                                name="revenue_date"
                                                label="Datum"
                                                defaultValue={formik.values.revenue_date}
                                                value={formik.values.revenue_date}
                                                onChange={formik.handleChange}
                                                //error={formik.touched.revenue_category && Boolean(formik.errors.revenue_category)}
                                                //helperText={formik.touched.revenue_category && formik.errors.revenue_category}
                                            />

                                            <TextField
                                                variant="standard"
                                                size="small"
                                                fullWidth
                                                id="revenue_description"
                                                name="revenue_description"
                                                label="Beschreibung"
                                                value={formik.values.revenue_description}
                                                onChange={formik.handleChange}
                                                //error={formik.touched.revenue_description && Boolean(formik.errors.revenue_description)}
                                                //helperText={formik.touched.revenue_description && formik.errors.revenue_description}
                                            />
                                            <TextField
                                                variant="standard"
                                                size="small"
                                                fullWidth
                                                id="revenue_category"
                                                name="revenue_category"
                                                label="Kategorie"
                                                value={formik.values.revenue_category}
                                                onChange={formik.handleChange}
                                                select
                                                //error={formik.touched.revenue_category && Boolean(formik.errors.revenue_category)}
                                                //helperText={formik.touched.revenue_category && formik.errors.revenue_category}
                                            >
                                                {category_list.map((row) => (
                                                    <MenuItem value={row.re_cat_description}>{row.re_cat_description}</MenuItem>
                                                ))}
                                            </TextField>

                                            <TextField
                                                id="revenue"
                                                name="revenue"
                                                label="Revenue"
                                                fullWidth
                                                value={formik.values.revenue}
                                                onChange={formik.handleChange}
                                                variant="standard"
                                                size="small"
                                                select
                                            >
                                                <MenuItem value="OUT">Ausgaben</MenuItem>
                                                <MenuItem value="IN">Einnahmen</MenuItem>
                                            </TextField>

                                            <TextField
                                                InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                                                variant="standard"
                                                size="small"
                                                fullWidth
                                                id="cash"
                                                name="cash"
                                                label="Cash"
                                                type={'number'}
                                                value={formik.values.cash}
                                                onChange={formik.handleChange}
                                                //error={formik.touched.revenue_description && Boolean(formik.errors.revenue_description)}
                                                //helperText={formik.touched.revenue_description && formik.errors.revenue_description}
                                            />

                                            <br></br>
                                            <br></br>
                                            <Button color="primary" variant="contained" type="submit">
                                                Speichern
                                            </Button>
                                        </form>
                                    </Box>
                                </Paper>
                            </div>
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Box sx={{ boxShadow: 3 }}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <h4>Wiederholende Ausgaben</h4>
                                    <ReExList month={month} update={reducerValue}></ReExList>
                                </Box>
                            </Paper>
                        </Box>
                        <br></br>
                        <Box sx={{ boxShadow: 3 }}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <h4>Laufende Spesen</h4>
                                    <MovementsList month={month} year={year} update={reducerValue}></MovementsList>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MvForm;
