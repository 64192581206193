import * as React from 'react';
import { ROUTES } from '../constants/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DashboardPage, MovementformPage, REFormPage } from '../pages';
import Layout from './Layout';

interface RoutersProp {}

const CustomMain = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Navigate to={ROUTES.main} />} />
                <Route path={ROUTES.main} element={<DashboardPage />} />
                <Route path={ROUTES.movmentform} element={<MovementformPage />} />
                <Route path={ROUTES.re_exp_from} element={<REFormPage />} />
            </Routes>
        </Layout>
    );
};

export default CustomMain;
