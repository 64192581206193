import { ReactNode } from 'react';
import Header from './Header';
import Drawer from './Drawer';
import * as React from 'react';
import { DrawerContextProvider } from '../contexts/drawer-context';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Footer from './Footer';

type Props = {
    children: NonNullable<ReactNode>;
};

const Layout: React.FunctionComponent<Props> = ({ children }) => {
    return (
        <DrawerContextProvider>
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    backgroundColor: 'lightgray'
                }}
            >
                <Header />
                <Toolbar />
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1
                    }}
                >
                    <Drawer />
                    <main style={{ flex: 1 }}>{children}</main>
                </Box>
                <Footer />
            </Box>
        </DrawerContextProvider>
    );
};

export default Layout;
