import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { DrawerItem } from '../ts';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';

type Props = DrawerItem & {
    selected?: boolean;
    onClick?: () => void;
};

const MenuItem: React.FC<Props> = ({ route, literal, Icon, selected, onClick }) => {
    const theme = useTheme();
    const link = (
        <ListItem
            button
            selected={selected}
            sx={{
                '&.Mui-selected': {
                    backgroundColor: theme.palette.info.dark,
                    color: 'common.white'
                },
                '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'common.white'
                }
            }}
            onClick={onClick}
        >
            <ListItemIcon
                sx={[
                    { minWidth: 'auto' },
                    (theme) => ({
                        paddingRight: theme.spacing(2)
                    })
                ]}
            >
                <Icon sx={{ color: 'success.main' }} />
            </ListItemIcon>
            <ListItemText primary={literal} />
        </ListItem>
    );
    return route ? <Link to={route}>{link}</Link> : link;
};

export default MenuItem;
