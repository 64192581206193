import { useEffect, useState, Fragment } from 'react';
import * as React from 'react';
import API from '../api/api_client';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { BalanceInterface } from '../api/Movement';

import { config } from '../api/config';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { blue, red } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import getSessionStorageOrDefault from '../shared/session';

interface BalanceProp {
    month: number;
    year: number;
    month_name: string;
}

const Balance: React.FunctionComponent<BalanceProp> = ({ month, year, month_name }) => {
    const [balance_list, setBalance] = useState<BalanceInterface[]>([]);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [dense, setDense] = React.useState(true);
    const sum = balance_list.reduce((sum, current) => (sum = sum + current.cash_balance), 0);
    const y = year;
    const periodo: string = `${month_name} ${year}`;
    const uri: string = `${config.baseUrl}/bl/${month}/${year}`;
    useEffect(() => {
        const token = getSessionStorageOrDefault('id_token', false);
        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${token}` : ''
            }
        };
        API.get(`bl/${month}/${year}`, customConfig)
            .then((response) => setBalance(response.data))
            .catch((error) => setError(error.message))
            .finally(() => setLoaded(true));
    }, [month, year]);
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                        €
                    </Avatar>
                }
                title="Balance"
                subheader={periodo}
            />
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 150 }} size="small" aria-label="a dense table">
                        <TableBody>
                            {balance_list.map((row) => (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{row.revenue == 'IN' ? 'Einnahmen' : 'Ausgaben'}:</TableCell>
                                    <TableCell align="right" style={{ color: row.cash_balance < 0 ? 'red' : 'blue' }}>
                                        {row.cash_balance.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <h4>Differenz:</h4>
                                </TableCell>
                                <TableCell align="right" style={{ color: sum < 0 ? 'red' : 'blue' }}>
                                    <h4> {sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</h4>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default Balance;
