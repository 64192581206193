import * as React from 'react';
import { useEffect, useState } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import CustomMain from './components/Main';
import GoogleLogIn from './components/GoogleLogIn';
import Box from '@mui/material/Box';
import { Container, stepButtonClasses } from '@mui/material';
import Card from '@mui/material/Card';
import { config } from './api/config';
import API from './api/api_client';
import getSessionStorageOrDefault from './shared/session';

import { DashboardPage, CustomersPage, MovementformPage, InventoryPage, REFormPage } from './pages';
import { Nightlife } from '@mui/icons-material';
interface LoginToken {
    email: string;
    jti: string;
    hd: string;
    sub: string;
    family_name: string;
    given_name: string;
}

interface LoginCredential {
    credential: string;
}

function App() {
    const [user, setUser] = useState<LoginToken>({ email: '', jti: '', family_name: '', given_name: '', hd: '', sub: '' });
    const [forbidden, setForbidden] = useState(true);
    const [id_token, setIDToken] = useState(`{"credential" : ""}`);
    const [token, setToken] = useState<LoginCredential>({ credential: '' });

    function handleCredentialResponse(response: any) {
        const userObject = jwt_decode<LoginToken>(response.credential);
        const json_token_id = `{"credential" : "${response.credential}"}`;
        setUser(userObject);

        const customConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${response.credential}` : ''
            }
        };
        const res = API.post(`rcli`, JSON.parse(json_token_id), customConfig)
            .then((res) => {
                sessionStorage.setItem('id_token', JSON.stringify(response.credential));
                setForbidden(false);
            })
            .catch((err) => {
                setForbidden(true);
            })
            .finally();
    }

    useEffect(() => {
        /* global google */

        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse
        });

        google.accounts.id.prompt();
        google.accounts.id.renderButton(document.getElementById('signInDiv'), { theme: 'outline', size: 'large' });
        console.log(forbidden);
    }, [forbidden]);
    return (
        <>
            {forbidden == false ? (
                <CustomMain />
            ) : (
                <Container maxWidth="sm">
                    <Box component="span" sx={{ p: 2 }}>
                        <h1>Login to HB</h1>
                    </Box>
                    <Box component="span" sx={{ p: 2 }}>
                        <div id="signInDiv"></div>
                    </Box>
                </Container>
            )}
        </>
    );
}

export default App;
