import MovementsList from '../components/Movemenst';
import ReList from '../components/RecurringExpenses';
import Balance from '../components/Balance';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Decrement from '@mui/icons-material/West';
import Increment from '@mui/icons-material/East';
import * as React from 'react';
import Paper from '@mui/material/Paper';

interface MvListProps {}

interface IState {
    month: number;
    year: number;
    new_date: Date;
}

const DashboardPage: React.FunctionComponent<MvListProps> = () => {
    const now = new Date();
    const dd = new Date(now.getFullYear(), now.getMonth(), 1);
    const [new_date, setNewDate] = useState(dd);
    const [month, setMonth] = useState(now.getMonth() + 1);
    const [year, setYear] = useState(now.getFullYear());
    const [current_month_name, setCurrentMonthName] = useState(new_date.toLocaleString('de-DE', { month: 'long' }));

    const decrement_month = (): any => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() - 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };

    const increment_month = (): any => {
        const nd = new Date(new_date.setMonth(new_date.getMonth() + 1));
        setNewDate(nd);
        setCurrentMonthName(new_date.toLocaleString('de-DE', { month: 'long' }));
        setYear(new_date.getFullYear());
        setMonth(new_date.getMonth() + 1);
    };

    return (
        <>
            <Box sx={{ m: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={4} textAlign="right">
                        <Button variant="outlined" startIcon={<Decrement />} onClick={decrement_month}></Button>
                    </Grid>
                    <Grid xs={2}>
                        <strong>
                            {current_month_name} {year}
                        </strong>
                    </Grid>
                    <Grid xs={4} textAlign="left">
                        <Button variant="outlined" startIcon={<Increment />} onClick={increment_month}></Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={7}>
                        <Box sx={{ boxShadow: 3 }}>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <h4>Ausgaben des Monat {current_month_name}</h4>

                                    <MovementsList month={month} year={year} update={0}></MovementsList>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>

                    <Grid xs={5}>
                        <Balance month={month} year={year} month_name={current_month_name}></Balance>
                        <br></br>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <h4>Wiederholende Ausgaben</h4>
                                <ReList month={month} update={0}></ReList>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DashboardPage;
