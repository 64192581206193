import { useEffect, useState, useReducer } from 'react';
import API from '../api/api_client';
import { MovementInterface } from '../api/Movement';
import { config } from '../api/config';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import { format } from 'date-fns';
import * as React from 'react';
import getSessionStorageOrDefault from '../shared/session';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

interface MvListProps {
    month: number;
    year: number;
    update: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const MovementsList: React.FunctionComponent<MvListProps> = ({ month, year, update }) => {
    const [mov_list, setMovments] = useState<MovementInterface[]>([]);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    const token = getSessionStorageOrDefault('id_token', false);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : ''
        }
    };

    useEffect(() => {
        API.get(`mvlistm/${month}/${year}`, customConfig)
            .then((response) => setMovments(response.data))
            .catch((error) => setError(error.message))
            .finally(() => setLoaded(true));
    }, [month, year, update, reducerValue]);

    const handleDeleteMovment = (value: Uint8Array) => () => {
        //alert(value);
        API.delete(`delmv/${value}`, customConfig)
            .catch((error) => setError(error.message))
            .finally(() => forceUpdate());

        // .catch((error) => setError(error.message);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell align="center">Kategorie</StyledTableCell>
                        <StyledTableCell align="center">Datum</StyledTableCell>
                        <StyledTableCell align="center">Beschreibung</StyledTableCell>
                        <StyledTableCell align="center">Cash</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mov_list.map((row) => (
                        <StyledTableRow>
                            <StyledTableCell width="20px" component="th" scope="row">
                                {row.id}
                            </StyledTableCell>
                            <StyledTableCell width="150px" align="left">
                                {row.revenue_category}
                            </StyledTableCell>
                            <StyledTableCell width="50px" align="left">
                                {new Date(row.revenue_date).toLocaleDateString('de-DE').split(',')[0]}
                            </StyledTableCell>

                            <StyledTableCell align="left">{row.revenue_description}</StyledTableCell>
                            <StyledTableCell width="100px" style={{ color: row.cash < 0 ? 'red' : 'blue' }} align="right">
                                {row.cash.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}
                            </StyledTableCell>
                            <StyledTableCell width="20px" align="center">
                                <IconButton size={'small'} aria-label="delete" onClick={handleDeleteMovment(row.id)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default MovementsList;
