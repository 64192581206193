import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import { useDrawerContext } from '../contexts/drawer-context';
//import { useTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { theme } from '../styles/theme';
import * as React from 'react';

const Header = () => {
    const { isOpened, toggleIsOpened } = useDrawerContext();
    return (
        <AppBar sx={{ backgroundColor: [theme.palette.primary.dark, theme.shadows[20]], color: 'secondary.contrastText' }}>
            <Toolbar>
                <IconButton color="inherit" onClick={() => toggleIsOpened(!isOpened)} sx={{ padding: theme.spacing(1) }}>
                    {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
                <Typography variant="h6" sx={{ margin: 'auto' }}>
                    HBuch
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
