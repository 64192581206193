import * as React from 'react';
import API from '../api/api_client';
import { useEffect, useState, useReducer } from 'react';
import { RecurringExpesnsesInterface } from '../api/Movement';
//import axios from 'axios';
import { config } from '../api/config';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import getSessionStorageOrDefault from '../shared/session';
import { AxiosInterceptorManager } from 'axios';
//import { format } from 'date-fns';

interface RecurringExpesnsesProps {
    month: number;
    update: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const RecurringExpesnsesList: React.FunctionComponent<RecurringExpesnsesProps> = ({ month, update }) => {
    const [re_list, setRe] = useState<RecurringExpesnsesInterface[]>([]);
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
    const sum = re_list.reduce((sum, current) => (sum = sum + current.re_cash), 0);
    const token = getSessionStorageOrDefault('id_token', false);
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : ''
        }
    };

    function getAllre(month: number) {
        if (month == 0) {
            API.get(`reall`, customConfig)
                .then((response) => setRe(response.data))
                .catch((error) => setError(error.message))
                .finally(() => setLoaded(true));
        } else {
            API.get(`remonth/${month}`, customConfig)
                .then((response) => setRe(response.data))
                .catch((error) => setError(error.message))
                .finally(() => setLoaded(true));
        }
    }

    useEffect(() => {
        getAllre(month);
    }, [month, update, reducerValue]);

    const handleDelete = (value: Uint8Array) => () => {
        //alert(value);
        API.delete(`delre/${value}`, customConfig)
            .catch((error) => setError(error.message))
            .finally(() => forceUpdate());

        // .catch((error) => setError(error.message);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell align="center">Beschreibung</StyledTableCell>
                        <StyledTableCell align="center">Tag</StyledTableCell>
                        <StyledTableCell align="center">Monat</StyledTableCell>
                        <StyledTableCell align="center">Cash</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {re_list.map((row) => (
                        <StyledTableRow>
                            <StyledTableCell width="10px" component="th" scope="row">
                                {row.id}
                            </StyledTableCell>
                            <StyledTableCell width="300px" align="left">
                                {row.re_description}
                            </StyledTableCell>
                            <StyledTableCell width="20px" align="center">
                                {row.re_day_of_month}
                            </StyledTableCell>
                            <StyledTableCell width="20px" align="center">
                                {row.re_month_of_year}
                            </StyledTableCell>
                            <StyledTableCell width="50px" align="right">
                                {row.re_cash.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}
                            </StyledTableCell>
                            <StyledTableCell width="20px" align="center">
                                <IconButton size={'small'} aria-label="delete" onClick={handleDelete(row.id)}>
                                    <DeleteIcon fontSize={'small'} />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        <StyledTableCell colSpan={3} />
                        <StyledTableCell>
                            <strong>Total</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <strong>{sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}</strong>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RecurringExpesnsesList;
