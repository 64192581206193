import { createTheme, withStyles } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#4a148c',
            dark: '#12005e',
            light: '#7c43bd'
        },
        secondary: {
            main: '#3949ab',
            dark: '#00227b',
            light: '#6f74dd'
        }
    }
});
