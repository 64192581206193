import { DrawerItem } from '../ts';
import { ROUTES } from './routes';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AddCircleOutline, Autorenew } from '@mui/icons-material';

export const DRAWER_LIST: DrawerItem[] = [
    {
        route: ROUTES.main,
        literal: 'Dashboard',
        Icon: DashboardIcon
    },
    {
        route: ROUTES.movmentform,
        literal: 'Insert Movement',
        Icon: AddCircleOutline
    },
    {
        route: ROUTES.re_exp_from,
        literal: 'Wiederholte Ausgaben',
        Icon: Autorenew
    }
];
